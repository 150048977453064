import { Label } from '@rebass/forms';
import { ErrorMessage, useField } from 'formik';
import P from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

import Text from '@/components/Text';

import Error from './Error';
import { Checkbox } from './RadioCheckIcons';

const AcceptContact = ({ content }) => {
  const [field] = useField({
    name: 'acceptContact',
    type: 'checkbox',
  });

  return (
    <Flex mt="6">
      <Label>
        <Checkbox {...field} sx={{ cursor: 'pointer' }} />
        <Box>
          <Text my={['0', '0', '0']} variant="small">
            {content}
          </Text>
          <ErrorMessage name="acceptContact">
            {(error) => <Error name="acceptContact" error={error} px="0" />}
          </ErrorMessage>
        </Box>
      </Label>
    </Flex>
  );
};

AcceptContact.propTypes = {
  content: P.string.isRequired,
};

export default AcceptContact;
