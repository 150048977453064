import React, { forwardRef } from 'react';
import { Box } from 'reflexbox';

const RadioImage = forwardRef(({ ...props }, ref) => {
  return (
    <Box>
      <Box
        ref={ref}
        as="input"
        type="radio"
        {...props}
        sx={{
          position: 'absolute',
          opacity: 0,
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
      />
    </Box>
  );
});

export default RadioImage;
