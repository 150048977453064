const PhoneIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.983" viewBox="0 0 15 14.983" {...props}>
    <path
      fill="#a09e9e"
      d="M6.244 3a1.356 1.356 0 0 0-.846.306l-.036.018-.018.018-1.8 1.853.018.018a1.8 1.8 0 0 0-.486 1.943v.018A18.077 18.077 0 0 0 7.252 13.7a19.013 19.013 0 0 0 6.531 4.174h.017a2.066 2.066 0 0 0 2-.4l1.817-1.817a1.288 1.288 0 0 0 0-1.781l-2.339-2.339-.018-.036a1.31 1.31 0 0 0-1.8 0l-1.151 1.151a9.313 9.313 0 0 1-2.359-1.61A7.316 7.316 0 0 1 8.349 8.7L9.5 7.552a1.229 1.229 0 0 0-.018-1.763l.018-.018-.054-.054-2.3-2.375-.018-.018-.038-.018A1.356 1.356 0 0 0 6.244 3zm0 1.151a.2.2 0 0 1 .126.054l2.3 2.357.054.054a.113.113 0 0 1-.036.126L7.252 8.181l-.27.252.126.36a9.333 9.333 0 0 0 2.051 3.094l.126.113a11.6 11.6 0 0 0 2.915 1.884l.36.162 1.709-1.709c.1-.1.081-.1.18 0l2.351 2.357c.1.1.1.063 0 .162l-1.763 1.763a.838.838 0 0 1-.882.162 17.755 17.755 0 0 1-6.1-3.886 17.075 17.075 0 0 1-3.9-6.117.731.731 0 0 1 .18-.756l.036-.036 1.747-1.781a.2.2 0 0 1 .126-.054z"
      transform="translate(-2.972 -3)"
    />
  </svg>
);

export default PhoneIcon;
