import { useField } from 'formik';
import PropTypes from 'prop-types';
import * as React from 'react';

import SelectBox from '@/components/Inputs/Select/SelectBox';

const Select = ({
  isClearable,
  disabled,
  isMulti,
  openMenuOnClick,
  closeMenuOnSelect,
  name,
  options,
  group = false,
  autoFocus = false,
  ...selectProps
}) => {
  const [{ value: formikValue }, , { setValue }] = useField({ name });
  const currentValue = React.useMemo(() => options?.find((option) => option.value === formikValue), [
    formikValue,
    options,
  ]);

  const handleOnFocus = () => {
    setValue('');
  };

  if (group && !isMulti) {
    const [currValue] = formikValue?.split('#');
    return (
      <SelectBox
        {...selectProps}
        name={name}
        disabled={disabled}
        value={formikValue ? { label: currValue, value: currValue } : null}
        onFocus={handleOnFocus}
        options={options}
        group
        autoFocus={autoFocus}
      />
    );
  }

  if (isMulti && group) {
    return (
      <SelectBox
        isClearable={isClearable}
        isMulti={isMulti}
        openMenuOnClick={openMenuOnClick}
        closeMenuOnSelect={closeMenuOnSelect}
        {...selectProps}
        name={name}
        options={options}
        isSearchable={false}
        group
        autoFocus={autoFocus}
      />
    );
  }

  return <SelectBox {...selectProps} name={name} disabled={disabled} options={options} value={currentValue} />;
};

Select.propTypes = {
  autoFocus: PropTypes.bool,
  closeMenuOnSelect: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  group: PropTypes.bool,
  isClearable: PropTypes.string.isRequired,
  isMulti: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  openMenuOnClick: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

Select.defaultProps = {
  group: false,
  autoFocus: false,
};

export default Select;
