import { Label } from '@rebass/forms';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Box, Flex, Image } from 'rebass/styled-components';
import styled from 'styled-components';
import { border, color, space } from 'styled-system';

import Text from '@/components/Text';

import { Radio } from './RadioCheckIcons';
import RadioImage from './RadioCheckImage';

const RadioWrapper = styled(Flex)`
  ${border};
  ${color};
  ${space};
  :last-child {
    margin-right: 0;
  }
  cursor: pointer;
  transition: 0.5s;
  :hover {
    border-color: #333333;
  }
  :checked {
    border-color: #333333;
  }

  ${({ checked }) => (checked ? 'border-color: #333333' : '')};
`;
RadioWrapper.defaultProps = {
  borderColor: '#ebebeb',
};

const RadioWrapperImage = styled(Flex)`
  ${border};
  ${color};
  ${space};
  width: calc(33.3% - 8px);
  :nth-child(3n) {
    margin-right: 0;
    width: calc(33.3%);
  }
  cursor: pointer;
  transition: 0.5s;
  :hover {
    border-color: #333333;
    background-color: #fafafa;
  }
  :checked {
    border-color: #333333;
  }
  @media (max-width: 768px) {
    width: calc(50% - 8px);
    &:nth-child(even) {
      margin-right: 0;
      width: calc(50%);
    }
    &:last-child {
      margin-right: 0;
      width: calc(50% - 8px);
    }
  }
  ${({ checked }) => (checked ? 'border-color: #333333' : '')};
`;
RadioWrapperImage.defaultProps = {
  borderColor: '#ebebeb',
};

const RadioBox = styled(Radio)`
  ${space};
  background: transparent !important;
`;

const RadioBoxImage = styled(RadioImage)`
  ${space};
  background: transparent !important;
`;

RadioBox.defaultProps = {
  height: '14px',
  width: '14px',
  color: '#333333',
};

const RadioButton = ({ isFullWidth, textVariant, disabled, name, value, label, isImageUrl }) => {
  const [field] = useField({ name, type: 'radio', value });

  return (
    <>
      {!isImageUrl ? (
        <RadioWrapper
          checked={field.checked}
          border={1}
          borderRadius={2}
          mr={isFullWidth ? ['0', '0', '0'] : ['8px', '8px', '8px']}
          mb={isFullWidth ? '12px' : '8px'}
          width={isFullWidth ? '100%' : 'auto'}
        >
          <Label
            justifyContent={isFullWidth ? 'left' : 'center'}
            alignItems="center"
            py={['8px', '8px', '8px']}
            px={['15px', '15px', '15px']}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Box sx={{ minWidth: '20px' }}>
              <RadioBox
                disabled={disabled}
                {...field}
                sx={{
                  'input:focus ~ &': {
                    bg: '#ffffff',
                  },
                }}
              />
            </Box>
            <Text variant={textVariant || 'input'}>{label}</Text>
          </Label>
        </RadioWrapper>
      ) : (
        <RadioWrapperImage
          mr={['8px', '8px', '8px']}
          mb={['8px', '8px', '8px']}
          minHeight={['140px', '140px', '140px']}
          checked={field.checked}
          border={1}
          borderRadius={2}
        >
          <Label
            alignItems="center"
            alignContent="flex-end"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            py={['15px', '15px', '15px']}
            px={['15px', '15px', '15px']}
            sx={{ cursor: 'pointer' }}
          >
            <RadioBoxImage {...field} />
            <Flex alignItems="flex-end" height="100%">
              <Image src={isImageUrl} alt="altText" height="fit-content" width="fit-content" />
            </Flex>
            <Text as="p" mb={['0', '0', '0']} pb={['0', '0', '0']} textAlign="center" variant="inputLabel">
              {label}
            </Text>
          </Label>
        </RadioWrapperImage>
      )}
    </>
  );
};

RadioButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isFullWidth: PropTypes.bool.isRequired,
  isImageUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  textVariant: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default RadioButton;
