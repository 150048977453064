import PropTypes from 'prop-types';

import InputWrapper from './InputWrapper';
import Radio from './Radio';

const RadioGroup = ({ isFullWidth, textVariant, name, title, disabled, tooltip, values, ...props }) => {
  return (
    <InputWrapper name={name} title={title} errorProps={{ mt: '-8px' }} tooltip={tooltip} {...props}>
      {values.map((item) => (
        <Radio
          isFullWidth={isFullWidth}
          name={name}
          key={item.value}
          value={item.value}
          label={item.label}
          isImageUrl={item.isImageUrl}
          textVariant={textVariant}
          disabled={disabled}
        />
      ))}
    </InputWrapper>
  );
};

RadioGroup.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isFullWidth: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  textVariant: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default RadioGroup;
