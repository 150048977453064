import { Label } from '@rebass/forms';
import { ErrorMessage, useField } from 'formik';
import P from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

import Text from '@/components/Text';

import Error from './Error';
import { Checkbox } from './RadioCheckIcons';

const CheckBox = ({ label, name, value, ...props }) => {
  const [field] = useField({
    name,
    type: 'checkbox',
    value,
  });

  return (
    <Flex py="4">
      <Label sx={{ cursor: 'pointer' }}>
        <Checkbox {...field} sx={{ cursor: 'pointer' }} />
        <Box>
          <Text {...props} my={['0', '0', '0']} variant="body">
            {label}
          </Text>
          <ErrorMessage name={name}>{(error) => <Error name={name} error={error} px="0" />}</ErrorMessage>
        </Box>
      </Label>
    </Flex>
  );
};
CheckBox.propTypes = {
  label: P.string.isRequired,
  name: P.string.isRequired,
  value: P.string.isRequired,
};

export default CheckBox;
