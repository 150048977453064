/* eslint-disable react/destructuring-assignment */
import { useField } from 'formik';
import PropTypes from 'prop-types';
import * as React from 'react';
import Highlighter from 'react-highlight-words';
import Select, { components } from 'react-select';
import { Box } from 'rebass/styled-components';
import styled, { ThemeContext } from 'styled-components';

import { ArrowIcon, Close } from '@/components/Icon';
import Text from '@/components/Text';

const Separator = styled.hr`
  border: #ebebeb 0.2px solid;
`;

function formatOptionLabel({ label }, { inputValue }) {
  return (
    <Highlighter
      searchWords={[inputValue]}
      textToHighlight={label}
      autoEscape
      highlightStyle={{ fontWeight: 600, backgroundColor: 'transparent' }}
    />
  );
}

const NoOptionsMessage = (props, text) => {
  return (
    <components.NoOptionsMessage {...props}>
      <Text variant="input">{text}</Text>
    </components.NoOptionsMessage>
  );
};

const MultiValueContainer = (props) => {
  return <components.MultiValueContainer {...props} />;
};

const GroupHeading = (props) => <components.GroupHeading {...props} />;

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <Close style={{ width: '8.8px', height: '8.8px', 'margin-left': '8px', cursor: 'pointer' }} />
    </components.MultiValueRemove>
  );
};

const MultiValueLabel = (props) => {
  return <components.MultiValueLabel {...props} />;
};

const MenuList = (props) => {
  return <components.MenuList {...props}>{props.children}</components.MenuList>;
};

MenuList.propTypes = {
  children: PropTypes.string.isRequired,
};

const DropdownIndicator = ({ selectProps, ...restProps }, group, options) => {
  const props = React.useMemo(() => {
    return {
      selectProps,
      ...restProps,
    };
  }, [selectProps, restProps]);

  if (group) {
    if (Array.isArray(options) || options?.length > 0) {
      return (
        <components.DropdownIndicator {...props}>
          <ArrowIcon
            style={{ transform: selectProps.menuIsOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}
            width="25px"
            heigth="25px"
          />
        </components.DropdownIndicator>
      );
    }

    if (!options) {
      return null;
    }
  }

  return (
    <components.DropdownIndicator {...props}>
      {!group ? (
        <ArrowIcon
          style={{ transform: selectProps.menuIsOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}
          width="25px"
          heigth="25px"
        />
      ) : null}
    </components.DropdownIndicator>
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
  }).isRequired,
};

const Group = ({ headingProps, ...restProps }) => {
  const groupProps = React.useMemo(
    () => ({
      ...restProps,
      headingProps,
    }),
    [headingProps, restProps]
  );

  return (
    <>
      {headingProps.id !== 'react-select-address-group-0-heading' &&
        headingProps.id !== 'react-select-reason-group-0-heading' && (
          <Box px={['20px']}>
            <Separator />
          </Box>
        )}
      {headingProps.id === 'react-select-reason-group-0-heading' && <Box mt={['10px']} />}
      <components.Group {...groupProps} />
    </>
  );
};

Group.propTypes = {
  headingProps: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const SelectBox = ({
  closeMenuOnSelect,
  openMenuOnClick,
  isMulti,
  disabled,
  name,
  options,
  group = false,
  onInputChange,
  customNoOptionText,
  isLoading,
  disableTouch = false,
  autoFocus = false,
  onMenuClose,
  loadingMessage,
  placeholder,
  ...props
}) => {
  const theme = React.useContext(ThemeContext);
  const [, , { setTouched, setValue }] = useField({ name });

  const handleChange = (e, { action }) => {
    if (group && action === 'clear') {
      setValue('');
    }
    if (group && isMulti) {
      setValue(e);
    } else {
      setValue(e?.value ?? '');
    }
  };

  const handleBlur = () => {
    if (disableTouch) setTouched(false);
    else setTouched(true);
  };

  return (
    <Select
      isDisabled={disabled}
      isClearable={group}
      formatOptionLabel={formatOptionLabel}
      isLoading={isLoading}
      onInputChange={onInputChange}
      options={options}
      theme={(selectTheme) => ({
        ...selectTheme,
        colors: { ...selectTheme.colors, neutral80: theme.colors.darkGrey },
      })}
      styles={{
        multiValue: () => ({
          backgroundColor: '#fafafa',
          padding: '4px 10px',
          display: 'flex',
          borderRadius: '5px',
          margin: '5px 0',
          marginRight: '5px',
        }),
        multiValueRemove: () => ({
          backgroundColor: '#fafafa',
        }),
        groupHeading: () => ({
          margin: '8px 20px 10px',
          textTransform: 'uppercase',
          color: '#A09E9E',
          fontWeight: '600',
          fontSize: '0.875rem',
          lineHeight: '1rem',
        }),
        multiValueLabel: () => ({
          backgroundColor: '#fafafa',
          color: '#333333',
          borderRadius: '5px',
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '20px',
        }),
        input: (styles) => {
          return {
            ...styles,
            fontFamily: 'Plus Jakarta Sans',
            fontWeight: 600,
            paddingLeft: '6px',
            '& input': {
              font: 'inherit',
            },
          };
        },
        container: (styles) => {
          return {
            ...styles,
            width: '100%',
            minHeight: '42px',
          };
        },
        control: (styles) => {
          const { radii } = theme;
          const [, , borderRadius] = radii;
          return {
            ...styles,
            fontWeight: 600,
            minHeight: '100%',
            borderColor: 'transparent',
            width: '100%',
            '&:hover': {},
            boxShadow: 'none',
            borderRadius: `${borderRadius}px`,
          };
        },
        singleValue: (styles) => {
          const [, fontWeight] = theme.fontWeights;
          return {
            ...styles,
            fontWeight,
            paddingLeft: '6px',
          };
        },
        placeholder: (styles) => {
          return {
            ...styles,
            paddingLeft: '6px',
          };
        },
        option: (styles, state) => {
          return {
            ...styles,
            fontSize: '14px',
            lineHeight: '24px',
            padding: '8px 16px',

            color: state.isDisabled ? theme.colors.disabled : theme.colors.darkGrey,
            fontWeight: state.isSelected ? 600 : 500,

            backgroundColor: state.isFocused && theme.colors.backgroundGrey,
            '&:hover': {
              backgroundColor: theme.colors.backgroundGrey,
            },
            '&:before': {
              padding: '3px 0',
              position: 'relative',
              left: '-16px',
              content: '""',
              borderLeft: state.isSelected ? '4px solid #e2001a' : '4px solid transparent',
              borderRadius: state.isSelected ? '0px 4px 4px 0px' : null,
            },
          };
        },
        menu: (styles) =>
          Array.isArray(options) && options.length > 0 ? { ...styles, zIndex: 2 } : { display: 'none' },
        indicatorsContainer: (styles) => {
          return {
            ...styles,
            marginRight: '3px',
          };
        },
      }}
      components={{
        Group,
        DropdownIndicator: (p) => DropdownIndicator(p, group, options),
        IndicatorSeparator: null,
        NoOptionsMessage: (p) => (customNoOptionText ? NoOptionsMessage(p, customNoOptionText) : null),
        MultiValueLabel,
        MultiValueRemove,
        MenuList,
        MultiValueContainer,
        GroupHeading,
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      classNamePrefix="finmas_select"
      onMenuClose={onMenuClose}
      loadingMessage={() => `${loadingMessage}`}
      id={name}
      instanceId={name}
      inputId={name}
      isMulti={isMulti}
      openMenuOnClick={openMenuOnClick}
      closeMenuOnSelect={closeMenuOnSelect}
      {...props}
      autoFocus={autoFocus}
    />
  );
};

SelectBox.propTypes = {
  autoFocus: PropTypes.bool,
  closeMenuOnSelect: PropTypes.string.isRequired,
  customNoOptionText: PropTypes.string,
  disableTouch: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  group: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.string.isRequired,
  loadingMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  onInputChange: PropTypes.func,
  onMenuClose: PropTypes.func,
  openMenuOnClick: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      find: PropTypes.func.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
};

SelectBox.defaultProps = {
  required: false,
  onInputChange: null,
  customNoOptionText: 'Keine Optionen',
  isLoading: false,
  onMenuClose: null,
  loadingMessage: 'Wird geladen...',
  placeholder: '',
  group: false,
  autoFocus: false,
  disableTouch: false,
};

export default SelectBox;
