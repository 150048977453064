/* eslint-disable no-nested-ternary */
import { Label } from '@rebass/forms';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Box, Flex } from 'rebass/styled-components';

import Text from '@/components/Text';
import Tooltip from '@/components/Tooltip/Tooltip';

import Error from './Error';

const FocusError = () => {
  const { isSubmitting, isValidating, errors } = useFormikContext();

  useEffect(() => {
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement = document.querySelector(`[name="${keys[0]}"]`);

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isSubmitting, isValidating, errors]);

  return null;
};

const InputWrapper = ({
  isInvert,
  children,
  hideError,
  name,
  isBaufi,
  consultantExtend,
  title,
  tooltip,
  errorProps,
  ...props
}) => {
  const [, meta] = useField({ name });
  const error = meta.touched && meta.error;
  const item = tooltip ? tooltip[0] : null;
  return (
    <Box
      name={name}
      sx={{ scrollMarginTop: '5rem' }}
      {...props}
      width="100%"
      style={{ padding: consultantExtend ? '0' : isInvert ? '0' : '6px 0' }}
    >
      <Label
        pl={['1.2em']}
        variant="text"
        htmlFor={name}
        maxWidth="400px"
        style={{ display: isBaufi || consultantExtend ? 'none' : '' }}
      >
        <Flex alignItems="center" justifyContent={['space-between', 'flex-start', ' ']} width="100%">
          <Text variant="inputLabel" color={error ? 'red' : isInvert ? 'white' : 'darkGrey'} marginRight="0.75rem">
            {title}
          </Text>
          {tooltip ? (
            <Tooltip name={item.name}>
              {item.title ? <Text variant="tooltipTitle">{item.title}</Text> : null}
              {item.text ? <Text variant="tooltipText">{item.text}</Text> : null}
              {item.title2 || item.text2 ? <Box mt={['1rem', '1rem']} /> : null}
              {item.title2 ? <Text variant="tooltipTitle">{item.title2}</Text> : null}
              {item.text2 ? <Text variant="tooltipText">{item.text2}</Text> : null}
            </Tooltip>
          ) : null}
        </Flex>
      </Label>
      {/* <Flex flexDirection="row" justifyContent="space-between">
        {children}
      </Flex> */}
      <Flex flexWrap="wrap">{children}</Flex>
      <FocusError />
      {!hideError && <Error name={name} error={error} {...errorProps} />}
    </Box>
  );
};

InputWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  consultantExtend: PropTypes.string,
  errorProps: PropTypes.string,
  hideError: PropTypes.bool,
  isBaufi: PropTypes.string,
  isInvert: PropTypes.bool,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

InputWrapper.defaultProps = {
  isInvert: false,
  consultantExtend: undefined,
  errorProps: undefined,
  hideError: undefined,
  isBaufi: undefined,
  tooltip: undefined,
};

export default InputWrapper;
