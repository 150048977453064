import { props as systemProps } from '@styled-system/should-forward-prop';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Box } from 'reflexbox';

const rebassProps = [...systemProps, 'sx', 'variant'];

const PRE = new RegExp(`^(${rebassProps.join('|')})$`);

const getProps = (test) => (props) => {
  const next = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in props) {
    if (test(key || '')) next[key] = props[key];
  }
  return next;
};

const getSystemProps = getProps((k) => PRE.test(k));

const SVG = ({ ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    viewBox="0 0 14 14"
    fill="currentcolor"
    {...props}
  />
);

const SVGCheck = ({ ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="currentcolor"
    {...props}
  />
);
const SVGCheckRound = ({ ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    fill="currentcolor"
    {...props}
  />
);

const RadioChecked = (props) => (
  <SVG {...props}>
    <g fill="#fff" stroke="#ebebeb">
      <circle cx="7" cy="7" r="7" stroke="none" />
      <circle cx="7" cy="7" r="6.5" fill="none" />
    </g>
    <circle cx="3" cy="3" r="3" fill="#333333" transform="translate(4 4)" />
  </SVG>
);

const RadioUnchecked = (props) => (
  <SVG {...props}>
    <g fill="#fff" stroke="#ebebeb">
      <circle cx="7" cy="7" r="7" stroke="none" />
      <circle cx="7" cy="7" r="6" fill="none" />
    </g>
  </SVG>
);

const RadioIcon = (props) => (
  <>
    <RadioChecked
      {...props}
      __css={{
        display: 'none',
        'input:checked ~ &': {
          display: 'block',
        },
      }}
    />
    <RadioUnchecked
      {...props}
      __css={{
        display: 'block',
        'input:checked ~ &': {
          display: 'none',
        },
      }}
    />
  </>
);

export const Radio = forwardRef(({ ...props }, ref) => (
  <Box>
    <Box
      disabled
      ref={ref}
      as="input"
      type="radio"
      {...props}
      sx={{
        position: 'absolute',
        opacity: 0,
        zIndex: -1,
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    />
    <Box
      as={RadioIcon}
      aria-hidden="true"
      tx="forms"
      {...getSystemProps(props)}
      __css={{
        mr: 2,
        borderRadius: 9999,
        color: 'gray',
        'input:checked ~ &': {
          color: 'primary',
        },
        'input:focus ~ &': {
          bg: 'highlight',
        },
      }}
    />
  </Box>
));

const CheckboxChecked = (props) => (
  <SVGCheck {...props}>
    <g id="Rechteck_1763" data-name="Rechteck 1763" fill="#fff" stroke="#333333" strokeWidth="1">
      <rect width="20" height="20" rx="2" stroke="none" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" fill="none" />
    </g>
    <g id="Rechteck_1762" data-name="Rechteck 1762" fill="#fff" stroke="#333333" strokeWidth="1">
      <rect width="20" height="20" rx="2" stroke="none" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" fill="none" />
    </g>
    <path
      id="check-solid"
      d="M13.109,6.281l-7.2,7.2L2.88,10.446l-.6.6,3.331,3.331.3.286.3-.286,7.5-7.5Z"
      transform="translate(1.927 -0.262)"
      fill="none"
      stroke="red"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
  </SVGCheck>
);

const CheckboxUnchecked = (props) => (
  <SVGCheck {...props}>
    <g id="Rechteck_1763" data-name="Rechteck 1763" fill="#fff" stroke="#333333" strokeWidth="1">
      <rect width="20" height="20" rx="2" stroke="none" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" fill="none" />
    </g>
  </SVGCheck>
);

const CheckboxIcon = (props) => (
  <>
    <CheckboxChecked
      {...props}
      __css={{
        display: 'none',
        'input:checked ~ &': {
          display: 'block',
        },
      }}
    />
    <CheckboxUnchecked
      {...props}
      __css={{
        display: 'block',
        'input:checked ~ &': {
          display: 'none',
        },
      }}
    />
  </>
);

/** @component */
export const Checkbox = forwardRef(({ sx, variant = 'checkbox', ...props }, ref) => (
  <Box pr={['15px', '15px', '15px']} minWidth="35px">
    <Box
      ref={ref}
      as="input"
      type="checkbox"
      {...props}
      sx={{
        position: 'absolute',
        opacity: 0,
        zIndex: -1,
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    />
    <Box
      as={CheckboxIcon}
      aria-hidden="true"
      tx="forms"
      variant={variant}
      sx={sx}
      {...getSystemProps(props)}
      __css={{
        color: 'gray',
        'input:checked ~ &': {
          color: 'primary',
        },
        'input:focus ~ &': {
          color: 'primary',
          bg: 'highlight',
        },
      }}
    />
  </Box>
));

Checkbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

Checkbox.defaultProps = {
  variant: 'checkbox',
};

// RoundCheckbox

const RoundCheckboxChecked = (props) => (
  <SVGCheckRound {...props}>
    <g transform="translate(0.5 0.5)">
      <rect width="20px" height="20px" fill="#e2001a" stroke="#e2001a" strokeMiterlimit="10" rx="10" />
      <path
        fill="#fff"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.757 6.281l-6.3 6.3-2.652-2.655-.524.524L5.2 13.366l.262.251.262-.251 6.56-6.56z"
        transform="translate(2.719 -0.281)"
      />
    </g>
  </SVGCheckRound>
);

const RoundCheckboxUnchecked = (props) => (
  <SVGCheckRound {...props}>
    <g transform="translate(0.5 0.5)">
      <rect width="20px" height="20px" fill="#fff" stroke="#d1d1d1" strokeMiterlimit="10" rx="10" />
    </g>
  </SVGCheckRound>
);

const RoundCheckboxHover = (props) => (
  <SVGCheckRound {...props}>
    <g transform="translate(0.5 0.5)">
      <rect width="20" height="20" fill="#fff" stroke="#d1d1d1" strokeMiterlimit="10" rx="10" />
      <path
        fill="none"
        stroke="#e2001a"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.757 6.281l-6.3 6.3-2.652-2.655-.524.524L5.2 13.366l.262.251.262-.251 6.56-6.56z"
        transform="translate(2.719 -0.281)"
      />
    </g>
  </SVGCheckRound>
);

const RoundCheckboxIcon = (props) => (
  <>
    <RoundCheckboxChecked
      {...props}
      __css={{
        display: 'none',
        'input:checked ~ &': {
          display: 'block',
        },
        'input:hover ~ &': {
          display: 'none',
        },
      }}
    />
    <RoundCheckboxUnchecked
      {...props}
      __css={{
        display: 'block',
        'input:checked ~ &': {
          display: 'none',
        },
        'input:hover ~ &': {
          display: 'none',
        },
      }}
    />
    <RoundCheckboxHover
      {...props}
      __css={{
        display: 'none',
        'input:hover ~ &': {
          display: 'block',
        },
      }}
    />
  </>
);

export const RoundCheckbox = forwardRef(({ sx, variant = 'checkbox', ...props }, ref) => (
  <Box>
    <Box
      ref={ref}
      as="input"
      type="checkbox"
      {...props}
      sx={{
        position: 'absolute',
        opacity: 0,
        zIndex: -1,
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    />
    <Box
      as={RoundCheckboxIcon}
      aria-hidden="true"
      tx="forms"
      variant={variant}
      sx={sx}
      {...getSystemProps(props)}
      __css={{
        'input:checked ~ &': {},
        'input:focus ~ &': {},
      }}
    />
  </Box>
));

RoundCheckbox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

RoundCheckbox.defaultProps = {
  variant: 'checkbox',
};
