import { Label } from '@rebass/forms';
import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';

import { RoundCheckbox } from './RadioCheckIcons';

const CheckTask = ({ width, height, ...props }) => {
  return (
    <Box py="4" minWidth="25px">
      <Label sx={{ cursor: 'pointer', height: 'fit-content' }}>
        <RoundCheckbox sx={{ cursor: 'pointer' }} width={width} height={height} {...props} />
      </Label>
    </Box>
  );
};

CheckTask.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
};

export default CheckTask;
