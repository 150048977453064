import { Input as RebassInput, Textarea } from '@rebass/forms';
import { themeGet } from '@styled-system/theme-get';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Box, Flex } from 'rebass/styled-components';
import styled from 'styled-components';
import { border, color, layout, space } from 'styled-system';

import EmailIcon from '@/components/Icon/EmailIcon';
import MobileIcon from '@/components/Icon/MobileIcon';
import PhoneIcon from '@/components/Icon/PhoneIcon';
import { Select } from '@/components/Inputs/Select';
import Text from '@/components/Text';

import InputWrapper from './InputWrapper';

const EuroIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 8.253 10.3">
    <path
      fill="#a09e9e"
      stroke="#a09e9e"
      strokeWidth="0.3px"
      d="M11 4a4.383 4.383 0 0 0-4.036 3.75H6v.833h.859c-.01.137-.026.277-.026.417s.016.28.026.417H6v.833h.964A4.383 4.383 0 0 0 11 14a3.846 3.846 0 0 0 2.891-1.393l-.638-.547A2.975 2.975 0 0 1 11 13.167a3.527 3.527 0 0 1-3.177-2.917h3.594v-.833H7.68c-.011-.138-.013-.275-.013-.417s0-.278.013-.417h3.737V7.75H7.823A3.527 3.527 0 0 1 11 4.833a2.975 2.975 0 0 1 2.253 1.107l.638-.547A3.846 3.846 0 0 0 11 4z"
      transform="translate(-5.85 -3.85)"
    />
  </svg>
);

const DateIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.348" height="15" viewBox="0 0 14.348 15">
    <path
      fill="#a09e9e"
      d="M7.609 4v.652H5V19h14.348V4.652h-2.609V4h-1.3v.652H8.913V4zM6.3 5.957h1.3v.652h1.3v-.652h6.522v.652h1.3v-.652h1.3v1.3H6.3zm0 2.609h11.743V17.7H6.3zm3.913 1.3v1.3h1.3V9.87zm2.609 0v1.3h1.3V9.87zm2.609 0v1.3h1.3V9.87zm-7.822 2.612v1.3h1.3v-1.3zm2.609 0v1.3h1.3v-1.3zm2.609 0v1.3h1.3v-1.3zm2.609 0v1.3h1.3v-1.3zm-7.827 2.609v1.3h1.3v-1.3zm2.609 0v1.3h1.3v-1.3zm2.609 0v1.3h1.3v-1.3z"
      transform="translate(-5 -4)"
    />
  </svg>
);

const TimeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <path
      d="M11.5 4a7.5 7.5 0 1 0 7.5 7.5A7.508 7.508 0 0 0 11.5 4zm0 1.25a6.25 6.25 0 1 1-6.25 6.25 6.24 6.24 0 0 1 6.25-6.25zm-.625 1.25v5.625h4.375v-1.25h-3.125V6.5z"
      transform="translate(-4 -4)"
      fill="#a09e9e"
    />
  </svg>
);

const MinusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="1.364" viewBox="0 0 15 1.364">
    <path fill="#e2001a" d="M5 15v1.364h15V15z" transform="translate(-5 -15)" />
  </svg>
);

const PlusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <path
      fill="#e2001a"
      d="M11.818 5v6.818H5v1.364h6.818V20h1.364v-6.818H20v-1.364h-6.818V5z"
      transform="translate(-5 -5)"
    />
  </svg>
);

const LivingSpaceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21.536" height="11.952" viewBox="0 0 21.536 11.952">
    <path
      fill="#a09e9e"
      stroke="#a09e9e"
      strokeWidth="0.3px"
      d="M12.064-8.56a3.444 3.444 0 0 1 2.536.928 3.714 3.714 0 0 1 .936 2.752V0H14v-4.7a2.675 2.675 0 0 0-.568-1.856 2.084 2.084 0 0 0-1.624-.624 2.432 2.432 0 0 0-1.856.728 2.925 2.925 0 0 0-.688 2.088V0H7.728v-4.7a2.675 2.675 0 0 0-.568-1.86 2.084 2.084 0 0 0-1.624-.624 2.432 2.432 0 0 0-1.856.728 2.925 2.925 0 0 0-.688 2.088V0H1.456v-8.48h1.472v1.264a2.976 2.976 0 0 1 1.216-1 4.072 4.072 0 0 1 1.712-.344 3.755 3.755 0 0 1 1.76.4A2.78 2.78 0 0 1 8.8-6.992a3.249 3.249 0 0 1 1.336-1.152 4.354 4.354 0 0 1 1.928-.416zm10.928 2.4v.928h-5.344v-.72l2.96-2.592a4.4 4.4 0 0 0 .76-.808 1.16 1.16 0 0 0 .2-.632.853.853 0 0 0-.4-.728 2.1 2.1 0 0 0-1.2-.28 2.847 2.847 0 0 0-1.056.184 1.94 1.94 0 0 0-.768.536l-.784-.608a3.219 3.219 0 0 1 2.688-1.072 3.149 3.149 0 0 1 1.952.528 1.64 1.64 0 0 1 .688 1.36 1.9 1.9 0 0 1-.256.96 4.829 4.829 0 0 1-.976 1.088L19.344-6.16z"
      transform="translate(-1.456 11.952)"
    />
  </svg>
);

const PercentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.704" height="11.424" viewBox="0 0 12.704 11.424">
    <path
      data-name="%"
      fill="#a09e9e"
      d="M3.2-5.168a2.439 2.439 0 0 1-1.936-.848A3.272 3.272 0 0 1 .528-8.24a3.272 3.272 0 0 1 .736-2.224 2.439 2.439 0 0 1 1.936-.848 2.474 2.474 0 0 1 1.944.84 3.243 3.243 0 0 1 .744 2.232 3.243 3.243 0 0 1-.744 2.232 2.474 2.474 0 0 1-1.944.84zM9.92-11.2h1.568L3.84 0H2.272zM3.2-6.288A1.17 1.17 0 0 0 4.216-6.8a2.477 2.477 0 0 0 .36-1.44 2.477 2.477 0 0 0-.36-1.44 1.17 1.17 0 0 0-1.016-.512 1.175 1.175 0 0 0-1 .52 2.4 2.4 0 0 0-.376 1.432A2.4 2.4 0 0 0 2.2-6.808a1.175 1.175 0 0 0 1 .52zm7.344 6.4a2.439 2.439 0 0 1-1.936-.848 3.272 3.272 0 0 1-.736-2.224 3.272 3.272 0 0 1 .736-2.224 2.439 2.439 0 0 1 1.936-.848 2.463 2.463 0 0 1 1.944.848 3.249 3.249 0 0 1 .744 2.224 3.249 3.249 0 0 1-.744 2.224 2.463 2.463 0 0 1-1.944.848zm0-1.12a1.169 1.169 0 0 0 1.008-.52 2.443 2.443 0 0 0 .368-1.432 2.443 2.443 0 0 0-.368-1.432 1.169 1.169 0 0 0-1.008-.52 1.175 1.175 0 0 0-1.008.512 2.436 2.436 0 0 0-.368 1.44 2.436 2.436 0 0 0 .368 1.44 1.175 1.175 0 0 0 1.008.512z"
      transform="translate(-.528 11.312)"
    />
  </svg>
);

const typeToIconMap = {
  mobile: MobileIcon,
  phone: PhoneIcon,
  email: EmailIcon,
  livingspace: LivingSpaceIcon,
};

export const InputContainer = styled(Box)`
  ${border};
  ${color};
  ${space};
  background-color: #fff;
  /* cursor: pointer; */
  transition: 0.5s;
  :hover {
    border-color: ${themeGet('colors.hoverBorder', '#707070')};
  }

  ${({ error, ...rest }) => (error ? `border-color: ${themeGet('colors.primary', '#e2001a')(rest)}` : '')};
`;
InputContainer.defaultProps = {
  borderColor: '#ebebeb',
  width: ['100%', 'auto', 'auto'],
  cursor: 'pointer',
};

const InputBox = styled(RebassInput)`
  ${border};
  ${color};
  ${space};
  ${layout};
  padding: 0 5px !important;
  border: none !important;
  box-shadow: none;
  color: #333333 !important;
  font: 600 1em 'Plus Jakarta Sans';
  outline: none;
  &:-webkit-autofill,
  &:-webkit-autofill::first-line,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    color: #333333;
    box-shadow: none;
    font: 600 1em 'Plus Jakarta Sans';
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #333333 !important;
  }
  &::placeholder {
    color: #a09e9e;
    font: 600 1em 'Plus Jakarta Sans';
  }
`;
InputBox.defaultProps = {
  background: 'transparent',
  border: 'none',
  borderColor: 'transparent',
  p: 0,
  type: 'text',
};

const TextArea = styled(Textarea)`
  resize: none;
`;
TextArea.defaultProps = {
  fontFamily: 'Plus Jakarta Sans',
  minHeight: '150px',
};

const StepperButton = ({ Icon, sx, ...props }) => (
  <Flex
    as="button"
    type="button"
    justifyContent="center"
    alignItems="center"
    height="100%"
    padding="9px"
    sx={{ cursor: 'pointer', border: '0', bg: 'transparent', ...sx }}
    {...props}
  >
    {Icon}
  </Flex>
);

StepperButton.propTypes = {
  Icon: PropTypes.node.isRequired,
  sx: PropTypes.shape({}),
};

StepperButton.defaultProps = {
  sx: undefined,
};

const InputStepper = ({ value, onChange }) => {
  const max = 6;
  const handleClick = (upDown) => {
    if ((value !== 0 || upDown) && (value <= max - 1 || !upDown)) {
      onChange(typeof value === 'string' ? parseFloat(value) : value + (upDown ? 1 : -1));
    }
  };

  return (
    <Flex width="100%" justifyContent="space-between" alignItems="center" sx={{ userSelect: 'none' }}>
      <StepperButton alt="Minus" Icon={<MinusIcon />} onClick={() => handleClick(false)} />
      <InputBox as="div" minWidth="50px" type="number" sx={{ textAlign: 'center' }}>
        {value}
      </InputBox>
      <StepperButton
        sx={{ marginRight: '2px' }}
        alt="Plus"
        padding="2px"
        Icon={<PlusIcon />}
        onClick={() => handleClick(true)}
      />
    </Flex>
  );
};

InputStepper.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

const InputNoLimitStepper = ({ value, onChange }) => {
  const max = 99999;
  const handleClick = (upDown) => {
    if ((value !== 0 || upDown) && (value <= max - 1 || !upDown)) {
      onChange(typeof value === 'string' ? parseFloat(value) : value + (upDown ? 1 : -1));
    }
  };

  return (
    <Flex width="100%" justifyContent="space-between" alignItems="center" sx={{ userSelect: 'none' }}>
      <StepperButton alt="Minus" Icon={<MinusIcon />} onClick={() => handleClick(false)} />
      <InputBox as="div" minWidth="50px" type="number" sx={{ textAlign: 'center' }}>
        {value}
      </InputBox>
      <StepperButton
        sx={{ marginRight: '2px' }}
        alt="Plus"
        padding="2px"
        Icon={<PlusIcon />}
        onClick={() => handleClick(true)}
      />
    </Flex>
  );
};

InputNoLimitStepper.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

const InputNoLimitHalfStepper = ({ value, onChange }) => {
  const max = 99999;
  const handleClick = (upDown) => {
    if ((value !== 0 || upDown) && (value <= max - 1 || !upDown)) {
      onChange(typeof value === 'string' ? parseFloat(value) : value + (upDown ? 0.5 : -0.5));
    }
  };

  return (
    <Flex width="100%" justifyContent="space-between" alignItems="center" sx={{ userSelect: 'none' }}>
      <StepperButton alt="Minus" Icon={<MinusIcon />} onClick={() => handleClick(false)} />
      <InputBox as="div" minWidth="50px" type="number" sx={{ textAlign: 'center' }}>
        {value}
      </InputBox>
      <StepperButton alt="Plus" padding="2px" Icon={<PlusIcon />} onClick={() => handleClick(true)} />
    </Flex>
  );
};

InputNoLimitHalfStepper.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

const InputField = ({
  customFontSize,
  alignText,
  isClearable,
  closeMenuOnSelect,
  openMenuOnClick,
  openMenuOnFocus,
  hideSelectedOptions,
  isMulti,
  defaultValue,
  placeholder,
  name,
  type,
  disabled,
  max,
  disableTouch,
  options,
  group = false,
  customNoOptionText,
  isLoading,
  loadingMessage,
  onInputChange,
  scale,
  minScale,
  autoFocus = false,
}) => {
  const [field, , { setValue, setTouched }] = useField({ name });
  const { onChange, ...restField } = field;

  const selectProps = React.useMemo(
    () => ({
      isClearable,
      closeMenuOnSelect,
      openMenuOnClick,
      openMenuOnFocus,
      hideSelectedOptions,
      defaultValue,
      isMulti,
      placeholder,
      name,
      options,
      group,
      customNoOptionText,
      isLoading,
      loadingMessage,
      disableTouch,
      onInputChange,
      scale,
      minScale,
    }),
    [
      isClearable,
      closeMenuOnSelect,
      openMenuOnClick,
      openMenuOnFocus,
      hideSelectedOptions,
      defaultValue,
      isMulti,
      customNoOptionText,
      disableTouch,
      group,
      isLoading,
      loadingMessage,
      name,
      onInputChange,
      options,
      placeholder,
      scale,
      minScale,
    ]
  );

  if (type === 'currency')
    return (
      <>
        <NumberFormat
          customInput={InputBox}
          placeholder={placeholder}
          disabled={disabled}
          fontSize={customFontSize || 'inherit'}
          type="tel"
          isNumericString
          allowNegative={false}
          decimalScale={scale || 0}
          fixedDecimalScale={minScale || 0}
          thousandSeparator="."
          decimalSeparator=","
          autoFocus={autoFocus}
          onValueChange={({ value: v }) => {
            setValue(v);
          }}
          {...restField}
        />
        <Text variant="inputLabelError" sx={{ marginRight: '6px' }}>
          <EuroIcon />
        </Text>
      </>
    );

  if (type === 'livingspace')
    return (
      <>
        <NumberFormat
          customInput={InputBox}
          placeholder={placeholder}
          type="tel"
          isNumericString
          allowNegative={false}
          decimalScale={0}
          autoFocus={autoFocus}
          thousandSeparator="."
          decimalSeparator=","
          onValueChange={({ value: v }) => {
            const formatValue = Number(v);
            setValue(formatValue);
          }}
          {...restField}
        />
        <Text variant="inputLabelError">
          <LivingSpaceIcon />
        </Text>
      </>
    );

  if (type === 'percent')
    return (
      <>
        <NumberFormat
          customInput={InputBox}
          placeholder={placeholder}
          type="tel"
          isNumericString
          allowNegative={false}
          decimalScale={2}
          autoFocus={autoFocus}
          disabled={disabled}
          decimalSeparator=","
          onValueChange={({ value: v }) => {
            const formatValue = Number(v);
            setValue(formatValue);
          }}
          {...restField}
        />
        <Text variant="inputLabelError">
          <PercentIcon />
        </Text>
      </>
    );

  if (type === 'date')
    return (
      <>
        <NumberFormat
          customInput={InputBox}
          placeholder={placeholder}
          type="tel"
          format="##.####"
          autoFocus={autoFocus}
          onValueChange={({ formattedValue }) => {
            setValue(formattedValue);
          }}
          {...restField}
        />
        <Text variant="inputLabelError" sx={{ marginRight: '5px' }}>
          <DateIcon />
        </Text>
      </>
    );

  if (type === 'dateYear')
    return (
      <>
        <NumberFormat
          customInput={InputBox}
          placeholder={placeholder}
          type="tel"
          format="####"
          autoFocus={autoFocus}
          onValueChange={({ formattedValue }) => {
            const value = Number(formattedValue);
            setValue(value);
          }}
          {...restField}
        />
        <Text variant="inputLabelError" sx={{ marginRight: '5px' }}>
          <DateIcon />
        </Text>
      </>
    );

  if (type === 'birthdate')
    return (
      <>
        <NumberFormat
          customInput={InputBox}
          placeholder="TT.MM.JJJJ"
          type="tel"
          disabled={disabled}
          format="##.##.####"
          autoFocus={autoFocus}
          onValueChange={({ formattedValue }) => {
            setValue(formattedValue);
          }}
          {...restField}
        />
        <Text variant="inputLabelError" sx={{ marginRight: '6px' }}>
          <DateIcon />
        </Text>
      </>
    );

  if (type === 'time')
    return (
      <>
        <NumberFormat
          customInput={InputBox}
          placeholder={placeholder}
          type="tel"
          format="##:##"
          autoFocus={autoFocus}
          onValueChange={({ formattedValue }) => {
            setValue(formattedValue);
          }}
          {...restField}
        />
        <Text variant="inputLabelError" sx={{ marginRight: '6px' }}>
          <TimeIcon />
        </Text>
      </>
    );

  if (type === 'stepper')
    return (
      <InputStepper
        value={field.value}
        autoFocus={autoFocus}
        onChange={(v) => {
          setValue(v);
          setTouched(true);
        }}
      />
    );

  if (type === 'nolimitstepper')
    return (
      <InputNoLimitStepper
        value={field.value}
        autoFocus={autoFocus}
        onChange={(v) => {
          setValue(v);
          setTouched(true);
        }}
      />
    );

  if (type === 'halfstepper')
    return (
      <InputNoLimitHalfStepper
        value={field.value}
        autoFocus={autoFocus}
        onChange={(v) => {
          setValue(v);
          setTouched(true);
        }}
      />
    );

  if (type === 'select') return <Select autoFocus={autoFocus} disabled={disabled} {...selectProps} />;

  if (type === 'textarea') {
    return (
      <TextArea
        id={name}
        name={name}
        inputId={name}
        maxLength={max}
        placeholder={placeholder}
        sx={{
          borderColor: 'transparent',
          outline: 'none',
          color: '#333333 !important',
          font: '600 1rem Plus Jakarta Sans',
        }}
        {...field}
      />
    );
  }

  if (type === 'postcode') {
    return (
      <InputBox
        {...field}
        placeholder={placeholder}
        disabled={disabled}
        type="text"
        autoFocus={autoFocus}
        maxLength="5"
        onChange={(e) => {
          const result = e.target.value.replace(/\D/, '');
          setValue(result);
        }}
        value={field.value}
      />
    );
  }

  const InputIcon = typeToIconMap[type] ?? null;

  if (type.includes('phone') || type === 'mobile') {
    return (
      <>
        <InputBox
          {...field}
          placeholder={placeholder}
          type="text"
          disabled={disabled}
          maxLength="25"
          onChange={(e) => {
            const result = e.target.value.replace(/[^0-9+-/\s]*$/, '');
            setValue(result);
          }}
          value={field.value}
        />
        {InputIcon ? (
          <Flex alignItems="center">
            <InputIcon />
          </Flex>
        ) : null}
      </>
    );
  }

  return (
    <>
      <InputBox
        {...field}
        disabled={disabled}
        placeholder={placeholder}
        type="text"
        autoFocus={autoFocus}
        textAlign={alignText}
      />
      {InputIcon ? (
        <Flex alignItems="center">
          <InputIcon />
        </Flex>
      ) : null}
    </>
  );
};

InputField.propTypes = {
  alignText: PropTypes.string,
  autoFocus: PropTypes.bool,
  closeMenuOnSelect: PropTypes.string,
  customFontSize: PropTypes.string,
  customNoOptionText: PropTypes.string,
  defaultValue: PropTypes.string,
  disableTouch: PropTypes.string,
  disabled: PropTypes.string,
  group: PropTypes.bool,
  hideSelectedOptions: PropTypes.string,
  isClearable: PropTypes.string,
  isLoading: PropTypes.string,
  isMulti: PropTypes.string,
  loadingMessage: PropTypes.string,
  max: PropTypes.number,
  minScale: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onInputChange: PropTypes.string,
  openMenuOnClick: PropTypes.string,
  openMenuOnFocus: PropTypes.string,
  options: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scale: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

InputField.defaultProps = {
  alignText: undefined,
  closeMenuOnSelect: undefined,
  customFontSize: undefined,
  customNoOptionText: undefined,
  defaultValue: undefined,
  disableTouch: undefined,
  disabled: undefined,
  group: undefined,
  hideSelectedOptions: undefined,
  isClearable: undefined,
  isLoading: undefined,
  isMulti: undefined,
  loadingMessage: undefined,
  onInputChange: undefined,
  openMenuOnClick: undefined,
  openMenuOnFocus: undefined,

  max: undefined,
  options: undefined,
  autoFocus: undefined,
};

const Input = ({
  isInvert,
  customFontSize,
  alignText,
  hideError,
  isClearable,
  closeMenuOnSelect,
  openMenuOnClick,
  openMenuOnFocus,
  defaultValue,
  hideSelectedOptions,
  isMulti,
  name,
  isBaufi,
  consultantExtend,
  title,
  placeholder,
  disabled,
  value,
  type,
  width,
  disableTouch,
  max,
  options,
  group,
  isLoading,
  loadingMessage,
  onInputChange,
  customNoOptionText,
  autoFocus,
  scale,
  minScale,
  ...props
}) => {
  const [, { error, touched }] = useField({ name });

  return (
    <InputWrapper
      isInvert={isInvert}
      name={name}
      title={title}
      cursor="none"
      {...props}
      isBaufi={isBaufi}
      hideError={hideError}
      consultantExtend={consultantExtend}
    >
      <InputContainer error={touched && error} border={1} borderRadius={2} width={width}>
        <Flex cursor="pointer" p={type === 'select' ? '0px' : ['12px', '12px', '12px']}>
          <InputField
            customFontSize={customFontSize}
            alignText={alignText}
            isClearable={isClearable}
            closeMenuOnSelect={closeMenuOnSelect}
            openMenuOnClick={openMenuOnClick}
            openMenuOnFocus={openMenuOnFocus}
            disableTouch={disableTouch}
            placeholder={placeholder}
            type={type}
            name={name}
            group={group}
            max={max}
            options={options}
            isLoading={isLoading}
            onInputChange={onInputChange}
            customNoOptionText={customNoOptionText}
            loadingMessage={loadingMessage}
            value={value}
            disabled={disabled}
            autoFocus={autoFocus}
            isMulti={isMulti}
            defaultValue={defaultValue}
            hideSelectedOptions={hideSelectedOptions}
            scale={scale || 0}
            minScale={minScale || 0}
          />
        </Flex>
      </InputContainer>
    </InputWrapper>
  );
};

Input.propTypes = {
  alignText: PropTypes.string,
  autoFocus: PropTypes.bool,
  closeMenuOnSelect: PropTypes.string,
  consultantExtend: PropTypes.string,
  customFontSize: PropTypes.string,
  customNoOptionText: PropTypes.string,
  defaultValue: PropTypes.string,
  disableTouch: PropTypes.string,
  disabled: PropTypes.string,
  group: PropTypes.string,
  hideError: PropTypes.bool,
  hideSelectedOptions: PropTypes.string,
  isBaufi: PropTypes.string,
  isClearable: PropTypes.string,
  isInvert: PropTypes.bool,
  isLoading: PropTypes.string,
  isMulti: PropTypes.string,
  loadingMessage: PropTypes.string,
  max: PropTypes.number,
  minScale: PropTypes.number,
  name: PropTypes.string.isRequired,
  onInputChange: PropTypes.string,
  openMenuOnClick: PropTypes.string,
  openMenuOnFocus: PropTypes.string,
  options: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scale: PropTypes.number,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
};

Input.defaultProps = {
  isInvert: false,
  alignText: undefined,
  closeMenuOnSelect: undefined,
  consultantExtend: undefined,
  customNoOptionText: undefined,
  customFontSize: undefined,
  defaultValue: undefined,
  disabled: undefined,
  disableTouch: undefined,
  group: undefined,
  hideError: undefined,
  hideSelectedOptions: undefined,
  isBaufi: undefined,
  isClearable: undefined,
  isLoading: undefined,
  isMulti: undefined,
  loadingMessage: undefined,
  onInputChange: undefined,
  openMenuOnClick: undefined,
  openMenuOnFocus: undefined,
  options: undefined,
  scale: undefined,
  minScale: undefined,
  value: undefined,

  type: 'text',
  width: undefined,
  placeholder: '',
  max: undefined,
  autoFocus: undefined,
};

export default Input;
