const EmailIcon = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.985" viewBox="0 0 18 17.985" {...props}>
    <path
      fill="#a09e9e"
      d="M13.134 4a8.993 8.993 0 1 0 5.482 16.024l-.937-1.171A7.5 7.5 0 1 1 13.111 5.5a7.584 7.584 0 0 1 7.379 7.262v.258a2.986 2.986 0 0 1-3 2.975 1.487 1.487 0 0 1-1.5-1.5v-6h-1.5v.586a3.321 3.321 0 0 0-1.871-.581 3.386 3.386 0 0 0-3.373 3.373v2.247a3.356 3.356 0 0 0 5.9 2.2 2.99 2.99 0 0 0 2.343 1.171 4.509 4.509 0 0 0 4.5-4.474v-.3A9.067 9.067 0 0 0 13.134 4zm-.515 6a1.865 1.865 0 0 1 1.874 1.874v2.246a1.874 1.874 0 1 1-3.748 0v-2.249A1.865 1.865 0 0 1 12.619 10z"
      transform="translate(-3.991 -3.999)"
    />
  </svg>
);

export default EmailIcon;
