import { Label } from '@rebass/forms';
import { ErrorMessage, useField } from 'formik';
import P from 'prop-types';
import { Box, Flex } from 'rebass/styled-components';

import Text from '@/components/Text';

import Error from './Error';
import { Checkbox } from './RadioCheckIcons';

const AcceptPrivacy = ({ preText, linkText, postText, url }) => {
  const [field] = useField({
    name: 'privacy',
    type: 'checkbox',
  });

  return (
    <Flex mb="6">
      <Label>
        <Checkbox {...field} sx={{ cursor: 'pointer' }} />
        <Box>
          <Text my={['0', '0', '0']} variant="small">
            {preText}
            <a href={url} rel="noreferrer" target="_blank">
              <Text as="span" my="0" interactive variant="smallPrimary">
                {`${linkText} `}
              </Text>
            </a>
            {postText}*
          </Text>
          <ErrorMessage name="privacy">{(error) => <Error name="privacy" error={error} px="0" />}</ErrorMessage>
        </Box>
      </Label>
    </Flex>
  );
};
AcceptPrivacy.propTypes = {
  preText: P.string.isRequired,
  linkText: P.string.isRequired,
  postText: P.string.isRequired,
  url: P.string.isRequired,
};

export default AcceptPrivacy;
