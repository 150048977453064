import useScrollPosition from '@react-hook/window-scroll';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { InfoIcon } from '../Icon';

const MyInfoIcon = styled(InfoIcon)`
  cursor: pointer;
`;

const MyReactTooltip = styled(ReactTooltip)`
  border-radius: 6px !important;
  padding: 20px !important;
  opacity: 1 !important;
  width: 336px;
  @media (max-width: 767px) {
    left: 16px !important;
    width: 343px !important;
  }
`;

const Tooltip = ({ children, name }) => {
  const [open, setOpen] = useState(false);
  const scrollY = useScrollPosition(30);

  useEffect(() => {
    if (open) {
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY]);

  return (
    <>
      <a
        data-tip
        data-for={name}
        currentitem={false}
        onMouseEnter={() => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
        }}
        style={{ height: '20px' }}
      >
        <MyInfoIcon
          color="#a09e9e"
          onClick={() => {
            setOpen(true);
          }}
        />
      </a>
      <div style={{ display: open ? 'contents' : 'none' }}>
        <MyReactTooltip
          aria-haspopup="true"
          arrowColor="transparent"
          backgroundColor="#3b3b3b"
          delayHide={200}
          delayShow={200}
          effect="solid"
          id={name}
          type="warning"
          open={open}
        >
          {children}
        </MyReactTooltip>
      </div>
    </>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default Tooltip;
