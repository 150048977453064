import PropTypes from 'prop-types';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { color } from 'styled-system';

import Text from '@/components/Text';

const ErrorContainer = styled(Box)`
  ${color};
  color: #e2001a;
`;
ErrorContainer.defaultProps = {
  color: '#e2001a',
};

const Error = ({ error, name, ...props }) => {
  if (!error) return null;

  return (
    <Box py="3" px={['1.2em']} htmlFor={name} {...props}>
      <ErrorContainer>
        <Text variant="smallPrimary">{error}</Text>
      </ErrorContainer>
    </Box>
  );
};

Error.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  name: PropTypes.string.isRequired,
};

Error.defaultProps = {
  error: undefined,
};

export default Error;
